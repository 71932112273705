import { isBrowser } from "../utility"

export default function CheckWebpFeature(){
  const KTestImages = {
    lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
    lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
    alpha:
      "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
    animation:
      "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA",
  }
  const Img = new Image()
  // eslint-disable-next-line func-names
  Img.onload = function () {
    const IsNotWebP = !(Img.width > 0) && Img.height > 0
    if (
      IsNotWebP &&
      IsNotWebP !== localStorage.getItem("GGFX-NOTWEBP") &&
      isBrowser()
    ) {
      localStorage.setItem("GGFX-NOTWEBP", IsNotWebP)
      window.reload()
    }
  }
  // eslint-disable-next-line func-names
  Img.onerror = function () {
    if (isBrowser()) {
      localStorage.setItem("GGFX-NOTWEBP", true)
      window.reload()
    }
  }
  // Feature as parameterized
  Img.src = `data:image/webp;base64,${KTestImages.lossless}`
  return 0
}

